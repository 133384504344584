<template>
  <div id="proveedor">
    <vx-card class="mb-6" >
      <div class="vx-row">
        <div class="vx-col w-full">
            <h1 class="rg-big-title extrabold rg-title">Catálogo de<span class="orange">productos</span>.</h1>
            <p class="text-subtitle mt-4">
              En este apartado podrás consultar la lista de marcas y modelos de los productos verificados en RedGirasol. 
            </p>
        </div>
      </div>
    </vx-card>

    <vx-card title="Lista de marcas registradas" class="mb-6">
                  
      <vs-table ref="brandsTable" stripe search noDataText="No se han generado registros" :data="brandsList" pagination :max-items="brandsPerPage">  
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <div v-if="!isSmallerScreen" class="flex flex-wrap-reverse items-center data-list-btn-container">
          </div>

          <!-- ITEMS PER PAGE -->
          <vs-dropdown v-if="isMounted" vs-trigger-click class="cursor-pointer mr-4 items-per-page-handler">
            <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentBrandsPage * brandsPerPage - (brandsPerPage - 1) }} - {{ brandsList.length - currentBrandsPage * brandsPerPage > 0 ? currentBrandsPage * brandsPerPage : brandsList.length }} de {{ queriedBrandItems }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item v-for="it in itemsPerPageDefinitions" @click="brandsPerPage=it" :key="it">
                <span>{{it}}</span>
              </vs-dropdown-item>            
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <template slot="thead">
          <vs-th class="bold" sort-key="brand">NOMBRE</vs-th>
          <vs-th class="bold" sort-key="product_group_name">FAMILIA DE PRODUCTOS</vs-th>
          <vs-th class="bold" sort-key="family_group_name">FAMILIA DE TECNOLOGÍAS</vs-th>

        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="data[indextr].product_brand_id">
              {{ data[indextr].brand }}
            </vs-td>

            <vs-td :data="data[indextr].product_group_id">
              {{ data[indextr].product_group_name }}
            </vs-td>

            <vs-td :data="data[indextr].family_group_id">
              {{ uppercase (data[indextr].family_group_name) }}
            </vs-td>

          </vs-tr>
        </template>
      </vs-table>
    </vx-card>

    <vx-card title="Lista de modelos registrados">
      <vs-alert icon-pack="feather" icon="icon-alert-triangle" class="h-full mb-1" color="warning">
        <span class="font-regular">Si deseas agregar una nuevo modelo que no se presente en en listado da clic a continuación. 
        <strong>Considera que los modelos deberán pasar por un proceso de verificación antes de aparecer en la lista general.</strong></span>
      </vs-alert>
      <vs-button v-if="isSmallerScreen" color="primary" type="border" icon-pack="feather" class="mb-4" @click="addModelRequest()">Agregar modelo</vs-button>      
      
      <vs-table ref="modelsTable" stripe search noDataText="No se han generado registros" :data="modelsList" pagination :max-items="modelsPerPage">
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <div v-if="!isSmallerScreen" class="flex flex-wrap-reverse items-center data-list-btn-container mr-4">
            <vs-button color="primary" type="border" icon-pack="feather" @click="addModelRequest()">Agregar modelo</vs-button>
          </div>

          <!-- ITEMS PER PAGE -->
          <vs-dropdown v-if="isMounted" vs-trigger-click class="cursor-pointer mr-4 items-per-page-handler">
            <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentModelsPage * modelsPerPage - (modelsPerPage - 1) }} - {{ modelsList.length - currentModelsPage * modelsPerPage > 0 ? currentModelsPage * modelsPerPage : modelsList.length }} de {{ queriedModelItems }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item v-for="it in itemsPerPageDefinitions" @click="modelsPerPage=it" :key="it">
                <span>{{it}}</span>
              </vs-dropdown-item>            
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <template slot="thead">
          <vs-th class="bold" sort-key="model">MODELO</vs-th>
          <vs-th class="bold" sort-key="brand">MARCA</vs-th>
          <vs-th class="bold" sort-key="product_group_name">FAMILIA DE PRODUCTOS</vs-th>
          <vs-th class="bold" sort-key="capacity">CAPACIDAD</vs-th>
          <vs-th class="bold" sort-key="status">ESTADO</vs-th>
          <vs-th class="bold">ACCIONES</vs-th>

        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="data[indextr].model">
              {{ data[indextr].model }}
            </vs-td>

            <vs-td :data="data[indextr].product_brand_id">
              {{ data[indextr].brand_name }}
            </vs-td>

            <vs-td :data="data[indextr].product_group_id">
              {{ data[indextr].product_name }}
            </vs-td>

            <vs-td :data="data[indextr].capacity">
              {{ data[indextr].capacity }} {{ data[indextr].capacity_units }}
            </vs-td>

            <vs-td :data="data[indextr].status">
              <span :class="getModelStatusClass(data[indextr].status)">{{getModelStatusText(data[indextr].status)}}</span>
            </vs-td>

            <vs-td :data="data[indextr].id">
              <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" @click.stop="openViewModal(data[indextr])" />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>      
    </vx-card>

    <!-- brand popup -->
    <vs-popup
        :title="brandFormTitle"
        :active.sync="showBrandFormPrompt">
        <div class="con-exemple-prompt">
          <vs-alert v-if="onError" class="h-full mb-5" color="danger">
            <span>{{ errorMssg }}</span>
          </vs-alert>

          <p class="mb-5 bold">{{brandFormPromptIndication}}</p>

          <label v-if="brandFormMode == 'add'" for="" class="vs-input--label">Sistema o tecnología</label>
          <v-select
            v-if="brandFormMode == 'add'"
            label="text"
            name="family_group_id"
            class="vs-custom w-full mb-5"
            v-model.lazy="family_group_id"
            :options="familyGroupsForSelect"
            :reduce="text => text.value"
            :clearable="false"
            :searchable="true"
            placeholder="Seleccione una opción" >
            <div slot="no-options">No hay coincidencias</div> 
          </v-select>
          <v-error v-if="hasError('family_group_id')" :error="errorText('family_group_id')" :success="isSuccess('family_group_id')"/>
  
          <label v-if="brandFormMode == 'add'" for="" class="vs-input--label">Grupo de productos</label>
          <v-select
            v-if="brandFormMode == 'add'"
            label="text"
            name="product_group_id"
            class="vs-custom w-full mb-5"
            v-model.lazy="product_group_id"
            :options="getProductGroupsForSelect"
            :reduce="text => text.value"
            :clearable="false"
            :searchable="true"
            :placeholder="getProductGroupsForSelect.length < 1 ? noDataPlaceholder : selectPlaceholder"
            :disabled="family_group_id == null || getProductGroupsForSelect.length < 1" >
            <div slot="no-options">No hay coincidencias</div> 
          </v-select>
          <v-error v-if="hasError('product_group_id')" :error="errorText('product_group_id')" :success="isSuccess('product_group_id')"/>

          <vs-input disabled
            class="w-full mb-4"
            v-if="brandFormMode == 'edit'"
            v-model="family_group_name"
            label="Sistema o tecnología" />

          <vs-input disabled
            class="w-full mb-4"
            v-if="brandFormMode == 'edit'"
            v-model="product_group_name"
            label="Sistema o tecnología" />

          <vs-input
            class="w-full mb-4" 
            label="Nombre de la Marca"
            placeholder="Requerido"
            v-validate="'required'"
            data-vv-name="brand"
            :danger="hasError('brand')"
            :danger-text="errorText('brand')"
            :success="isSuccess('brand')"
            v-on:keyup.enter="sendBrandForm"
            v-model="brand" />

          <vs-button :disabled="!completeBrandForm || $isLoading" @click="sendBrandForm" color="primary" class="mr-5 mt-2">{{ brandFormAction }}</vs-button>
          <vs-button @click="closeBrandFormPrompt" :disabled="$isLoading" type="flat" color="primary">Cerrar</vs-button>
            
        </div>
      </vs-popup>

      <vs-popup
        :title="modelFormTitle"
        :active.sync="showModelFormPrompt">
        <div class="con-exemple-prompt">
          <vs-alert v-if="onError" class="h-full mb-5" color="danger">
            <span>{{ errorMssg }}</span>
          </vs-alert>

          <template v-if="showModelFormPrompt">
            <h4 class="text-primary mb-4">Paso 1: <strong>Agregar modelo</strong></h4>
            <p v-if="currentProcessStep > 0" class="mb-5 bold text-success">El modelo fue creado exitosamente</p>

            <template v-if="currentProcessStep == 0">  
              <p class="mb-5 bold">Llena el formulario con la información del modelo</p>
              <div class="vx-row">
                <div class="vx-col w-1/2">

                <label v-if="modelFormMode == 'add'" for="" class="vs-input--label">Sistema o tecnología</label>
                <v-select
                  v-if="modelFormMode == 'add'"
                  label="text"
                  name="family_group_id"
                  class="vs-custom w-full mb-5"
                  v-model.lazy="selectedFamilyGroup"
                  :options="familyGroupsForSelect"
                  :reduce="text => text.value"
                  :clearable="false"
                  :searchable="true"
                  placeholder="Seleccione una opción" >
                  <div slot="no-options">No hay coincidencias</div> 
                </v-select>
                <v-error v-if="hasError('selectedFamilyGroup')" :error="errorText('selectedFamilyGroup')" :success="isSuccess('selectedFamilyGroup')"/>

                  <vs-input disabled
                    class="w-full mb-5"
                    v-if="modelFormMode == 'edit'"
                    v-model="family_group_name"
                    label="Sistema o tecnología" />
                </div>


                <div class="vx-col w-1/2">
                  <label v-if="modelFormMode == 'add'" for="" class="vs-input--label">Grupo de productos</label>
                  <v-select
                    v-if="modelFormMode == 'add'"
                    label="text"
                    name="product_group_id"
                    class="vs-custom w-full mb-5"
                    v-model.lazy="selectedProductGroup"
                    :options="getModalProductGroups"
                    :reduce="text => text.value"
                    :clearable="false"
                    :searchable="true"
                    :placeholder="getModalProductGroups.length < 1 ? noDataPlaceholder : selectPlaceholder"
                    :disabled="selectedFamilyGroup == null || getModalProductGroups.length < 1" >
                    <div slot="no-options">No hay coincidencias</div> 
                  </v-select>
                  <v-error v-if="hasError('selectedProductGroup')" :error="errorText('selectedProductGroup')" :success="isSuccess('selectedProductGroup')"/>

                  <vs-input disabled
                    class="w-full mb-5"
                    v-if="modelFormMode == 'edit'"
                    v-model="product_group_name"
                    label="Grupo de productos" />
                </div>
              </div>

              <label for="" class="vs-input--label">Marca</label>
              <v-select
                label="text"
                name="brand_id"
                class="vs-custom w-full mb-5"
                v-model.lazy="selectedProductBrand"
                :options="getModalProductBrands"
                :reduce="text => text.value"
                :clearable="false"
                :searchable="true"
                :placeholder="getModalProductBrands.length < 1 ? noDataPlaceholder : selectPlaceholder"
                :disabled="getModalProductBrands.length < 1" >
                <div slot="no-options">No hay coincidencias</div> 
              </v-select>
              <v-error v-if="hasError('selectedProductBrand')" :error="errorText('selectedProductBrand')" :success="isSuccess('selectedProductBrand')"/>

              <vs-input
                class="w-full mb-4" 
                label="Nombre del modelo"
                placeholder="Requerido"
                v-validate="'required'"
                data-vv-name="model"
                :danger="hasError('model')"
                :danger-text="errorText('model')"
                :success="isSuccess('model')"
                v-model.lazy="model" />

              <div class="vx-row">
                <div class="vx-col w-full" v-if="should_fill_capacity">
                  <vs-input
                    class="w-full mb-4" 
                    :label="modelCapacityUnitLabel"
                    placeholder="Requerido"
                    v-validate="'required|min_value:0'"
                    :min="0"
                    data-vv-name="capacity"
                    type="number"
                    :danger="hasError('capacity')"
                    :danger-text="errorText('capacity')"
                    :success="isSuccess('capacity')"
                    v-model.lazy="capacity" />
                </div>
              </div>
            </template>

            <h4 v-if="currentProcessStep > 0" class="text-primary mb-4">Paso 2: <strong>Agregar certificaciones</strong></h4>
            <p v-if="currentProcessStep > 1" class="mb-5 bold text-success">{{ getCertificationsText }}</p>

            <template v-if="currentProcessStep == 1">
              <div class="vx-row mb-4">
                <div class="vx-col w-full">
                  <vs-alert icon-pack="feather" icon="icon-alert-triangle" class="h-full mb-1" color="secondary">
                    <span class="font-regular">Por el momento no es necesario que completes este apartado ya que aún se encuentra en desarrollo. Por favor, da clic en "Guardar y continuar"</span>
                  </vs-alert>
                </div>
              </div>

              <certifications-table
                ref="certificationsTableRef"
                :selectedCertificationsList="selectedCertificationsList"
                :certificationsList="certificationsList"
                :currentModel="currentModel" />
            </template>

            <template v-if="currentProcessStep == 2">
              <h4 class="text-primary mb-4">Paso 2: <strong>Adjuntar ficha técnica del modelo</strong></h4>

              <div class="vx-row mb-4">
                <div class="vx-col w-full">
                  <vs-alert icon-pack="feather" icon="icon-alert-triangle" class="h-full mb-1" color="secondary">
                    <span class="font-regular">El documento de ficha técnica podra subirse aún después de la creación del modelo</span>
                  </vs-alert>
                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col w-full">
                  <document-upload
                    :f="dataSheetDocumentField"
                    :doc="dataSheetDocument"
                    :inputClassExtraLarge="true"
                    :target_id="currentModel.id"
                    :evaluator="getDataSheetDocumentData"
                    :automatic="true"
                    :isGeneralDoc="true"
                  />
                </div>
              </div>
            </template>

          </template>

          <vs-button v-if="currentProcessStep != 2" :disabled="!isModelCompleted || $isLoading" @click="handleProcessAction" color="primary" class="mr-5 mt-2">{{ modelFormAction }}</vs-button>
          <vs-button @click="closeModelFormPrompt" type="flat" color="primary">Cerrar</vs-button>
            
        </div>
      </vs-popup>

      <vs-popup :title="getViewModalTitle" :active.sync="isViewModalShown">
        <div>
          <vs-alert v-if="onError" class="h-full mb-5" color="danger">
            <span>{{ errorMssg }}</span>
          </vs-alert>

          <template v-if="currentModel">

            <div class="vx-row">
              <div class="vx-col w-full my-4">
                <h4 class="text-warning">Información general</h4>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col w-1/2">
                <label class="vs-input--label">Sistema o tecnología</label>
                <v-select
                  label="text"
                  class="vs-custom w-full mb-5"
                  v-model.lazy="selectedFamilyGroup"
                  :options="familyGroupsForSelect"
                  :reduce="text => text.value"
                  :clearable="false"
                  :searchable="true"
                  :disabled="currentModel.status == 2"
                  placeholder="Seleccione una opción" >
                  <div slot="no-options">No hay coincidencias</div> 
                </v-select>
              </div>
              <div class="vx-col w-1/2">
                <label class="vs-input--label">Grupo de productos</label>
                <v-select
                  label="text"
                  class="vs-custom w-full mb-5"
                  v-model.lazy="selectedProductGroup"
                  :options="getModalProductGroups"
                  :reduce="text => text.value"
                  :clearable="false"
                  :searchable="true"
                  :disabled="currentModel.status == 2"
                  placeholder="Seleccione una opción" >
                  <div slot="no-options">No hay coincidencias</div> 
                </v-select>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-full">
                <label class="vs-input--label">Marca</label>
                <v-select
                  label="text"
                  class="vs-custom w-full mb-5"
                  v-model.lazy="selectedProductBrand"
                  :options="getModalProductBrands"
                  :reduce="text => text.value"
                  :clearable="false"
                  :searchable="true"
                  :disabled="currentModel.status == 2"
                  placeholder="Seleccione una opción" >
                  <div slot="no-options">No hay coincidencias</div> 
                </v-select>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full mb-4" 
                  label="Nombre del modelo"
                  placeholder="Requerido"
                  data-vv-name="model"
                  :disabled="currentModel.status == 2"
                  v-model.lazy="model" />
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full mb-4" 
                  :label="`Capacidad - ${currentModel.capacity_units}`"
                  placeholder="Requerido"
                  data-vv-name="model"
                  :disabled="currentModel.status == 2"
                  v-model.lazy="capacity" />
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col w-full my-4">
                <h4 class="text-warning">Verificación</h4>
              </div>
            </div>
            <div class="vx-row mb-3">
              <div class="vx-col w-full">
                <span>Estado de verificación: </span><span :class="getModelStatusClass(currentModel.status)">{{getModelStatusText(currentModel.status)}}</span>
              </div>
            </div>
            <div class="vx-row mb-3" v-if="currentModel.verification_request_date">
              <div class="vx-col w-full">
                <span>Fecha de envio a verificación: </span><span :class="getModelStatusClass(currentModel.status)">{{currentModel.verification_request_date}}</span>
              </div>
            </div>
            <div class="vx-row mb-3" v-if="currentModel.status == 2 && currentModel.verified_date">
              <div class="vx-col w-full">
                <span>Fecha de verificación: </span><span :class="getModelStatusClass(currentModel.status)">{{currentModel.verified_date}}</span>
              </div>
            </div>
            <div class="vx-row mb-3" v-if="currentModel.supplier_tradename">
              <div class="vx-col w-full">
                <span>Enviado por: {{currentModel.supplier_tradename}}</span>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col w-full my-4">
                <h4 class="text-warning">Certificaciones</h4>
              </div>
            </div>
            <certifications-table
                ref="certificationsTableRef"
                :selectedCertificationsList="selectedCertificationsList"
                :certificationsList="certificationsList"
                :editable="!(currentModel.status == 2)"
                :currentModel="currentModel" />
            
            <div class="vx-row">
              <div class="vx-col w-full my-4">
                <h4 class="text-warning">Ficha técnica</h4>
              </div>
            </div>
            <document-upload
              v-if="currentModel.supplier_id == SupplierId && currentModel.status != 2"
              :f="dataSheetDocumentField"
              :doc="dataSheetDocument"
              :inputClassExtraLarge="true"
              :target_id="currentModel.id"
              :evaluator="getDataSheetDocumentData"
              :automatic="true"
              :isGeneralDoc="true"
            />
            <download-button
              v-else-if="currentModel.status == 2 && currentModel.document_file_id != null"
              title="Ficha técnica"
              :actionLink="getDataSheetURL"
              :fileName="`Ficha-Tecnica-${currentModel.id}.pdf`"
              :remoteFile="false" />

          <div class="vx-row">
            <div class="vx-col w-full">
              <vs-button :disabled="isEditSaveButtonDisabled" @click="handleEditModel" v-if="currentModel.status != 2" color="primary" class="mr-5 mt-2">Guardar</vs-button>
              <vs-button @click="isViewModalShown = false" type="flat" color="primary">Cerrar</vs-button>
            </div>
          </div>

          </template>

        </div>
      </vs-popup>

  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper"
import CertificationsTable from './CertificationsTable.vue';
const addBrandIndicationDef = "Selecciona la tecnología y grupo de productos que corresponde a la marca a crear.";
const editBrandIndicationDef = 
"Puedes modificar únicamente el nombre de la marca. Si deseas cambiar el grupo de productos o la familia de tecnologías, puedes eliminar este registro y generar uno nuevo.";

const addModelIndicationDef = "Selecciona la marca a la que pertenece el modelo a crear.";
const editModelIndicationDef = 
"Puedes modificar el nombre del modelo, la capacidad y la marca a la que pertenece.";
const errorMssgDef = "Ha ocurrido un error, intente más tarde."
const itemsPerPageDefinitions = [5,10,25,50];
const dataSheetDocumentField = { 
  doc_id: 171, 
  field_name: 'Ficha técnica',
  extras: JSON.stringify({
    doc_assoc: {
      table: 'product_models',
      assoc_key: 'id',
      condition: 'getDataSheetCondition'
    }
  })
};

export default {
  mixins: [inputHelper],
  components: {
    CertificationsTable
  },
  data(){
    return {
      dataSheetDocumentField: dataSheetDocumentField,
      dataSheetDocument: null,
      currentModel: null,
      isViewModalShown: false,
      selectedFamilyGroup: null,
      selectedProductGroup: null,
      selectedProductBrand: null,

      isMounted: false,
      familyGroupsForSelect: [],
      getProductGroupsForSelect: [],
      getProductBrandsForSelect: [],

      getResourceImpactUnitsForSelect: [],
      
      productGroupsList: [],
      brandsList: [],
      modelsList: [],

      currentProcessStep: 0,
      selectedCertificationsList: [],
      certificationsList: [],

      brandFormMode: "add",
      modelFormMode: "add",
      
      // datos para form de marca
      family_group_id: null,
      product_group_id: null,
      brand: null,
      prev_brand: null,
      selected_brand_id: null,
      family_group_name: null,
      product_group_name: null,

      // datos para form de modelo
      model: null,
      brand_id: null,
      product_brand_id: null, 
      selected_model_id: null,
      capacity: null,
      lifespan: null,
      degradation_per_year: null,
      resource_impact: null,
      resource_impact_units: null,

      showBrandFormPrompt: false,
      showModelFormPrompt: false,
      skipFamGroupWatch: false,
      skipProductGroupWatch: false,

      onError: false,
      onSuccess: false,
      errorMssg: errorMssgDef,

      maxScreen: 630,

      itemsPerPageDefinitions: itemsPerPageDefinitions,
      brandsPerPage: itemsPerPageDefinitions[0],
      modelsPerPage: itemsPerPageDefinitions[0],

      selectPlaceholder: "Seleccione una opción",
      noDataPlaceholder: "No hay opciones disponibles",

      currentBrandName: null,
      currentModelName: null,      
    }
  },
  async mounted(){
    this.isMounted = false;
    this.showLoading(true);
    await this.getCollections();
    await this.loadSavedBrands();
    await this.loadSavedModels();
    await this.getCertificationsList();
    await this.getDataSheetDocument();
    this.showLoading(false);
    this.isMounted = true;
  },
  watch: {
    isViewModalShown(newValue) {
      if(!newValue) {
        this.currentModel = null;
        this.selectedCertificationsList = [];
      }
      this.getModelCertificationsList();
    },

    showModelFormPrompt(newValue) {
      if(!newValue) {
        this.currentModel = null;
        this.currentProcessStep = 0;
      }
    },

    family_group_id(newVal, oldVal){
      if(newVal == null){
        this.getProductGroupsForSelect = [];
      }
      else {
        let collection = this.productGroupsList.filter(f => f.family_group_id == newVal);
        this.getProductGroupsForSelect = [];
        collection.forEach(opt => {
          this.getProductGroupsForSelect.push({value: opt.id, text: opt.name}); //conversion a sintaxis de vx-select 
        })
      }
      this.product_group_id = null;
    },

    product_group_id(newVal, oldVal){
      if(this.modelFormMode == "edit"){
        return;
      }

      this.setBrandsCollection(newVal);      
      this.brand_id = null;
      this.resource_impact_units = null;
    },
  },
  computed: {
    isEditSaveButtonDisabled() {
      if(this.selectedCertificationsList.length < 1)
        return true;
      if(this.selectedProductBrand == null)
        return true;
      if(this.model == null || this.model.length < 1)
        return true;
      if(this.capacity == null || this.capacity.length < 1)
        return true;
    },
    getCertificationsText() {
      return 'Certificaciones agregadas exitosamente';
    },
    getDataSheetURL() {
      if(this.currentModel)
        return `/storagev3/documents/view_document_file/${this.currentModel.document_file_id}/${this.currentModel.id}?access_token=${this.AccessToken}`
    },
    getViewModalTitle() {
      if(this.isViewModalShown)
        return `Visualización detallada de modelo ${this.currentModel.model} (${this.currentModel.brand_name})`;
    },
    isModelCompleted() {
      if(this.currentProcessStep == 0)
        return this.selectedProductBrand != null &&
          (this.model != null && this.model.length > 0) &&
          (this.capacity != null && this.capacity.length > 0);
      return true;
    },
    getModalProductGroups() {
      this.selectedProductGroup = null;
      if(this.currentModel)
        this.selectedProductGroup = this.currentModel.product_group_id;

      let collection = this.productGroupsList.filter((productGroup) => productGroup.family_group_id == this.selectedFamilyGroup);
      return collection.map((productGroup) => ({ value: productGroup.id, text: productGroup.name }));
    },
    getModalProductBrands() {
      this.selectedProductBrand = null;
      if(this.currentModel && this.selectedProductGroup == this.currentModel.product_group_id) 
        this.selectedProductBrand = this.currentModel.product_brand_id;

      let collection = this.brandsList.filter((brand) => brand.product_group_id == this.selectedProductGroup);
      return collection.map((brand) => ({ value: brand.id, text: brand.brand }));
    },
    isSmallerScreen () {
      return this.$store.state.windowWidth <= this.maxScreen
    },
    currentBrandsPage () {
      if (this.isMounted) {
        return this.$refs.brandsTable.currentx;
      }
      return 0
    },    
    queriedBrandItems () {
      return this.$refs.brandsTable ? this.$refs.brandsTable.queriedResults.length : this.brandsList.length
    },
    currentModelsPage () {
      if (this.isMounted) {
        return this.$refs.modelsTable.currentx;
      }
      return 0
    },
    queriedModelItems () {
      return this.$refs.modelsTable ? this.$refs.modelsTable.queriedResults.length : this.modelsList.length
    },
    brandFormTitle(){
      return this.brandFormMode == "add" ? "Agregar una marca" : "Editar la marca";
    },
    brandFormAction(){
      return this.brandFormMode == "add" ? "Agregar" : "Actualizar";
    },
    brandFormPromptIndication(){
      return this.brandFormMode == "add" ? addBrandIndicationDef : editBrandIndicationDef;
    },
    modelCapacityUnitLabel(){
      if(this.brand_id != null){
        let b = this.brandsList.find(f => f.product_brand_id == this.brand_id);
        return `Capacidad - ${b.product_group_capacity_units}`;
      }
      return "Capacidad";
    },
    completeBrandForm(){
      if(this.brandFormMode == "add"){
        return this.family_group_id != null && this.product_group_id != null && this.brand != null && this.brand.length > 1;
      }
      else {
        return this.brand != null && this.brand.length > 1;
      }
    },
    modelFormTitle(){
      return this.modelFormMode == "add" ? "Agregar un modelo" : "Editar el modelo";
    },
    modelFormAction(){
      if(this.currentProcessStep == 0)
        return 'Agregar modelo';
      return 'Guardar y continuar';
    },
    modelFormPromptIndication(){
      return this.modelFormMode == "add" ? addModelIndicationDef : editModelIndicationDef;
    },
    completeModelForm(){
      return this.brand_id != null && this.model != null && 
      (this.should_fill_capacity ? (this.capacity != null && this.capacity > 0) : true ); //&&
      // (this.should_fill_resource_impact ? (this.resource_impact != null && this.resource_impact > 0 && this.resource_impact_units != null) : true) &&
      // (this.should_fill_lifespan ? (this.lifespan != null && this.lifespan > 0) : true) &&
      // (this.should_fill_dpy ? (this.degradation_per_year != null && this.degradation_per_year > 0 && this.degradation_per_year < 100) : true);
    },
    should_fill_resource_impact(){
      if(this.product_group_id){
        return this.productGroupsList.find(f => f.id == this.product_group_id).resource_impact_required == 1;
      }
    },
    should_fill_lifespan(){
      if(this.product_group_id){
        return this.productGroupsList.find(f => f.id == this.product_group_id).lifespan_required == 1;
      }
    },
    should_fill_dpy(){
      if(this.product_group_id){
        return this.productGroupsList.find(f => f.id == this.product_group_id).degradation_per_year_required == 1;
      }
    },
    should_fill_capacity(){
      return true;
    }
  },
  methods: {
    async getModelCertificationsList() {
			try {
				const {data} = await axios.get(`/api/v1/certifications/model/${this.currentModel.id}`);
				if(data)
					this.selectedCertificationsList = data.message;
			} 
			catch (error) {
				this.warn(error);
        this.failed = true;				
			}
		},
    async getCertificationsList() {
			try {
				const {data} = await axios.get('/api/v1/certifications');
				if(data)
					this.certificationsList = data;
			} 
			catch (error) {
				this.warn(error);
        this.failed = true;				
			}
		},
    getDataSheetDocumentData(condition) {
      if(condition != null && condition in this)
        return this[condition]();
    },
    getDataSheetCondition() {
      if(this.currentModel) {
        if(this.currentModel.supplier_id) 
          return this.currentModel.supplier_id == this.SupplierId;
        return true;
      }
    },
    async getDataSheetDocument() {
      this.dataSheetDocument = await this.getDocumentById(171);
    },
    async getDocumentById(documentId) {
      try {
        let result = await axios.get(`/api/v1/forms/document/${documentId}`);
        if(result && result.data)
          return result = result.data.document;
      } 
      catch (error) {
        this.warn(error);
        this.onError = true;
      }
    },
    openViewModal(productModel) {
      this.currentModel = productModel;
      this.selectedFamilyGroup = this.currentModel.family_group_id;
      this.model = this.currentModel.model;
      this.capacity = this.currentModel.capacity;
      this.isViewModalShown = true;
    },
    getModelStatusText(status) {
      switch(status) {
        case 1: return 'Verificación pendiente';
        case 2: return 'Verificado';
        case 3: return 'Rechazado';
      }
    },
    getModelStatusClass(status) {
      switch(status) {
        case 1: return 'text-warning';
        case 2: return 'text-success';
        case 3: return 'text-danger';
      }
    },
    async handleProcessAction() {
      switch(this.currentProcessStep) {
        case 0: 
          await this.handleAddModel();
          break;
        case 1: 
          await this.handleAddCertifications();
          break;
      }
    },
    async handleAddCertifications() {
      // if(this.selectedCertificationsList.length < 1) {
      //   this.onError = true;
      //   this.errorMssg = 'Se debe seleccionar al menos una certificación';
      //   return;
      // }

      const result = await this.$refs.certificationsTableRef.handleOnSave();
      if(result) {
        this.onError = false;
        this.selectedCertificationsList = [];
        this.currentProcessStep++;
      }
    },
    async handleAddModel() {
      this.onSuccess = false;
      this.onError = false;

      if(this.isModelDuplicated(this.model)) {
        this.onError = true;
        this.errorMssg = "Ya existe un modelo con el nombre " + this.model;
        this.showLoading(false);
        return;
      }

      try {
        this.showLoading(true);
        let {data} = await axios.post(`/api/v1/supplier/models`, {
          supplierId: this.SupplierId,
          productBrandId: this.selectedProductBrand,
          modelName: this.model,
          capacity: this.capacity
        });
        if(data)
          this.currentModel = data.productModel;

        this.currentProcessStep++;
        this.onSuccess = true;
        await this.loadSavedModels();
        this.selectedFamilyGroup = null;
      } 
      catch (error) {
        console.log(error);
        this.warn(error);
        this.onError = true;
      }
      this.showLoading(false);
    },
    async handleEditModel() {
      this.onSuccess = false;
      this.onError = false;

      if(this.model != this.currentModel.model && this.isModelDuplicated(this.model)) {
        this.onError = true;
        this.errorMssg = "Ya existe un modelo con el nombre " + this.model;
        this.showLoading(false);
        return;
      }
      if(this.selectedCertificationsList.length < 1) {
        this.onError = true;
        this.errorMssg = 'Se debe seleccionar al menos una certificación';
        this.showLoading(false);
        return;
      }

      try {
        this.showLoading(true);
        await this.$refs.certificationsTableRef.handleOnSave();
        let {data} = await axios.put(`/api/v1/supplier/models/${this.currentModel.id}`, {
          productBrandId: this.selectedProductBrand,
          modelName: this.model,
          capacity: this.capacity
        });
        if(data)
          this.currentModel = data.productModel;

        this.onSuccess = true;
        await this.loadSavedModels();
      } 
      catch (error) {
        console.log(error);
        this.warn(error);
        this.onError = true;
      }
      this.showLoading(false);
    },
    isModelDuplicated(model) {
      const newModelName = model.toLowerCase().trim();

      for (const {model} of this.modelsList) {
        const existingModelName = model.toLowerCase().trim();
        if(existingModelName == newModelName)
          return true;
      }
    },
    dgpy(item){
      let p = this.productGroupsList.find(f => f.id == item.product_group_id);
      if(p.degradation_per_year_required){
        return item.degradation_per_year * 100;        
      }
      else {
        return "N/A";
      }
    },
    lfspn(item){
      let p = this.productGroupsList.find(f => f.id == item.product_group_id);
      if(p.lifespan_required){
        return item.lifespan;
      }
      else {
        return "N/A";
      }
    },
    rsimpact(item){
      let p = this.productGroupsList.find(f => f.id == item.product_group_id);
      if(p.resource_impact_required){
        let res = item.resource_impact;
        let units = p.resource_impact_units;
        if(units != null){
          let opts = JSON.parse(units);
          let u = opts.find(f => f.k == item.resource_impact_units);
          if(u){
            res += " " + u.v;
          }
        }
        return res;
      }
      else {
        return "N/A";
      }
    },
    setBrandsCollection(product_group_id){
      if(product_group_id == null){
        this.getProductBrandsForSelect = [];
        this.getResourceImpactUnitsForSelect = [];        
      }
      else {
        let collection = this.brandsList.filter(f => f.product_group_id == product_group_id);
        this.getProductBrandsForSelect = [];
        collection.forEach(opt => {
          this.getProductBrandsForSelect.push({value: opt.product_brand_id, text: opt.brand});
        });

        this.getResourceImpactUnitsForSelect = [];
        let pg = this.productGroupsList.find(f => f.id == product_group_id);
        let units = pg.resource_impact_units;
        if(units != null && units != "null"){
          let opts = JSON.parse(units);
          opts.forEach(opt => {
            this.getResourceImpactUnitsForSelect.push({value: opt.k, text: opt.v});
          })
        }
      }
    },
    async getCollections(){
      try {
        let response = await axios.get('/api/v1/forms/getFamilyGroups');
        let options = [];
        response.data.forEach(opt => {
          options.push({value: opt.id, text: opt.name}); //conversion a sintaxis de vx-select 
        })
        this.familyGroupsForSelect = options;

        let response2 = await axios.get(`/api/v1/forms/getProductGroups`);   
        this.productGroupsList = response2.data;
      } catch (e) {
        this.warn(e);
        this.failed = true;
      }
    },
    async loadSavedBrands(){
      try {
        let res = await axios.get(`/api/v1/supplier/getProductBrands/${this.SupplierId}`);
        this.brandsList = res.data;
      }
      catch (e) {
        this.warn(e);
      }
    },
    async loadSavedModels(){
      try {
        let res = await axios.get(`/api/v1/supplier/${this.SupplierId}/models`);
        this.modelsList = res.data;
      }
      catch (e) {
        this.warn(e);
      }
    },
    async sendBrandForm(){
      if(!this.completeBrandForm){
        return;
      }

      if(this.brandFormMode == "add"){
        await this.saveBrand();
      }
      else if(this.prev_brand != this.brand){
        await this.updateBrand();
      }
      else {
        this.closeBrandFormPrompt();
      }
    },
    async saveBrand(){
      this.onSuccess = false;
      this.onError = false;

      // verificar que el nombre de la marca no se repita
      let exists = this.checkForSimilarBrand();
      if(exists){
        this.onError = true;
        this.errorMssg = "Ya existe una marca con el nombre " + this.brand;
        return;
      }
      
      this.showLoading(true);
      try {        
        let payload = { brand: this.brand, product_group_id: this.product_group_id};
        await axios.post(`/api/v1/supplier/${this.SupplierId}/addSupplierProductBrand`, payload);
        this.onSuccess = true;
        this.addSuccessNotif();
        await this.loadSavedBrands();
        this.closeBrandFormPrompt();
      }
      catch (error) {
        this.warn(error);
        this.onError = true;
      }
      this.showLoading(false);
    },
    async updateBrand(){
      this.onSuccess = false;
      this.onError = false;

      // verificar que el nombre de la marca no se repita
      let exists = this.checkForSimilarBrand();
      if(exists){
        this.onError = true;
        this.errorMssg = "Ya existe una marca con el nombre " + this.brand;
        return;
      }
      
      this.showLoading(true);
      try {        
        let payload = { brand: this.brand, id: this.selected_brand_id};
        this.currentBrandName = this.brand;
        await axios.post('/api/v1/supplier/updateSupplierProductBrand', payload);
        this.onSuccess = true;
        this.saveSuccessNotif();
        await this.loadSavedBrands();
        await this.loadSavedModels();
        this.closeBrandFormPrompt();
      }
      catch (error) {
        this.warn(error);
        this.onError = true;
      }
      this.showLoading(false);
    },
    async sendModelForm(){
      if(this.modelFormMode == "add"){
        await this.saveModel();
      }
      else{
        await this.updateModel();
      }      
    },
    async saveModel(){
      this.onSuccess = false;
      this.onError = false;

      // verificar que el nombre de la marca no se repita
      let exists = this.checkForSimilarModel();
      if(exists){
        this.onError = true;
        this.errorMssg = "Ya existe un modelo con el nombre " + this.model;
        return;
      }
      
      this.showLoading(true);
      try {
        let payload = { product_brand_id: this.brand_id, model: this.model, capacity: this.capacity, lifespan: this.lifespan,
         degradation_per_year: this.degradation_per_year, resource_impact: this.resource_impact, resource_impact_units: this.resource_impact_units
         , family_group_id: this.family_group_id};
        await axios.post(`/api/v1/supplier/${this.SupplierId}/addSupplierProductModel`, payload);
        this.onSuccess = true;
        this.addSuccessNotif();
        this.closeModelFormPrompt();
        await this.loadSavedModels();
      }
      catch (error) {
        this.warn(error);
        this.onError = true;
      }
      this.showLoading(false);
    },
    async updateModel(){
      this.onSuccess = false;
      this.onError = false;

       // verificar que el nombre de la marca no se repita
      let prev = this.modelsList.find(f => f.id == this.selected_model_id);
      if(this.model != prev.model){
        let exists = this.checkForSimilarModel();
        if(exists){
          this.onError = true;
          this.errorMssg = "Ya existe un modelo con el nombre " + this.model;
          return;
        }
      }
      
      this.showLoading(true);
      try {        
        let payload = {id: this.selected_model_id, product_brand_id: this.brand_id, model: this.model, capacity: this.capacity, 
        lifespan: this.lifespan, degradation_per_year: this.degradation_per_year, resource_impact: this.resource_impact, resource_impact_units: this.resource_impact_units
        , family_group_id: this.family_group_id};
        this.currentModelName = this.modelsList.find(f => f.id == this.selected_model_id).model;
        await axios.post('/api/v1/supplier/updateSupplierProductModel', payload);
        this.onSuccess = true;
        this.saveSuccessNotif();
        this.showModelFormPrompt = false;
        await this.loadSavedModels();
      }
      catch (error) {
        this.warn(error);
        this.showModelFormPrompt = false;
        this.onError = true;
        this.processEditModel(error);
      }
      this.showLoading(false);
    },
    checkForSimilarBrand(){
      let bdown = this.brand.toLowerCase().trim();
      let exists = false;
      this.brandsList.forEach(f => {
        let b = f.brand.toLowerCase().trim();
        if(bdown == b){
          exists = true;
          return;
        }
      });
      return exists;
    },
    addBrandRequest(){
      this.resetBrandForm();
      this.brandFormMode = "add";
      this.showBrandFormPrompt = true;
    },
    editBrandRequest(item){
      this.resetBrandForm();
      this.brandFormMode = "edit";
      this.showBrandFormPrompt = true;

      this.selected_brand_id = item.product_brand_id;
      this.family_group_name = item.family_group_name;
      this.product_group_name = item.product_group_name;
      this.prev_brand = item.brand;
      this.brand = item.brand;
    },
    removeBrandRequest(item){
      this.selected_brand_id = item.product_brand_id;
      
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Descartar marca',
        text: `¿Realmente desea eliminar la marca ${item.brand}?`,
        acceptText: "Eliminar",
        cancelText: 'Cancelar',
        accept: this.doRemoveBrand
      });
    },
    resetBrandForm(){
      this.family_group_id = null
      this.product_group_id = null
      this.brand = null
      this.prev_brand = null
      this.onError = null
      this.onSuccess = null
      this.modelFormMode = null;
    },
    closeBrandFormPrompt() {
      this.showBrandFormPrompt = false;
      this.resetBrandForm();
    },
    async doRemoveBrand(){
      this.onSuccess = false;
      this.onError = false;
      this.showLoading(true);
      
      try {
        let payload = {id: this.selected_brand_id};
        this.currentBrandName = this.brandsList.find(f => f.product_brand_id == this.selected_brand_id).brand;
        await axios.post('/api/v1/supplier/removeSupplierProductBrand', payload);
        this.onSuccess = true;
        this.deleteSuccessNotif();
        await this.loadSavedBrands();
        await this.loadSavedModels();
      }
      catch (error) {
        this.processDeleteBrand(error);
        this.onError = true;
      }
      this.showLoading(false);
    },
    checkForSimilarModel(){
      let bdown = this.model.toLowerCase().trim();
      let exists = false;
      this.modelsList.forEach(f => {
        let b = f.model.toLowerCase().trim();
        if(bdown == b){
          exists = true;
          return;
        }
      });
      return exists;
    },
    addModelRequest(){
      this.resetModelForm();
      this.modelFormMode = "add";
      this.showModelFormPrompt = true;
    },
    editModelRequest(item){
      this.resetModelForm();
      this.modelFormMode = "edit";
      this.selected_model_id = item.id;
      this.product_brand_id = item.product_brand_id;
      this.product_group_id = item.product_group_id;
      this.family_group_id = item.family_group_id;

      let ff = this.brandsList.find(f => f.product_brand_id == item.product_brand_id);
      this.family_group_name = ff.family_group_name;
      this.product_group_name = item.product_group_name;
      this.setBrandsCollection(item.product_group_id);
      this.brand_id = item.product_brand_id;
      
      this.capacity = item.capacity;
      this.lifespan = item.lifespan;
      this.resource_impact = item.resource_impact;
      this.resource_impact_units = item.resource_impact_units;
      this.degradation_per_year = item.degradation_per_year * 100; 
      this.model = item.model;
      this.showModelFormPrompt = true;
    },  
    resetModelForm(){
      this.family_group_id = null
      this.product_group_id = null
      this.brand_id = null
      this.model = null
      this.capacity = null
      this.lifespan = null
      this.degradation_per_year = null
      this.resource_impact = null
      this.resource_impact_units = null
      this.onError = null
      this.onSuccess = null      
    },
    removeModelRequest(item){
      this.selected_model_id = item.id;
      
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Descartar modelo',
        text: `¿Realmente desea eliminar el modelo ${item.model} ?`,
        acceptText: "Eliminar",
        cancelText: 'Cancelar',
        accept: this.doRemoveModel
      });
    },
    async doRemoveModel(){
      this.onSuccess = false;
      this.onError = false;
      this.showLoading(true);
      
      try {
        let payload = {id: this.selected_model_id};
        this.currentModelName = this.modelsList.find(f => f.id == this.selected_model_id).model;
        await axios.post('/api/v1/supplier/removeSupplierProductModel', payload);
        this.onSuccess = true;
        this.deleteSuccessNotif();
        await this.loadSavedModels();
      }
      catch (error) {
        this.processDeleteModel(error);
        this.onError = true;
      }
      this.showLoading(false);
    },
    closeModelFormPrompt() {
      this.showModelFormPrompt = false;
      this.resetModelForm();
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);      
    },
    processDeleteBrand(error){
      this.warn(error.response);
      if(error.response && error.response.data){
        let m = error.response.data;
        if(m.error_code == "has_models"){
          this.errorNotifDialog("Descartar marca", `No es posible eliminar la marca ${this.currentBrandName}, ya que cuenta con ${m.info} modelos relacionados.
           Si deseas eliminar la marca definitivamente, es necesario eliminar antes los modelos que utilizan esta marca.`);
          return;
        }
      this.failedOperationNotif();
      }
    },
    processDeleteModel(error){
      this.warn(error.response);
      if(error.response && error.response.data){
        let m = error.response.data;
        if(m.error_code == "has_projects_related"){
          this.errorNotifDialog("Descartar modelo", `No es posible eliminar el modelo ${this.currentModelName}, dado que ya está siendo utilizado 
           en uno o más proyectos.`);
          return;
        }
      }
      this.failedOperationNotif();
    },
    processEditModel(error){
      this.warn(error.response);
      if(error.response && error.response.data){
        let m = error.response.data;
        if(m.error_code == "has_projects_related"){
          this.errorNotifDialog("Actualización del modelo", `No es posible modificar los datos del modelo ${this.currentModelName}, dado que ya está siendo utilizado 
           en uno o más proyectos.`);
          return;
        }
      }
      this.failedOperationNotif();
    }
  }
}
</script>