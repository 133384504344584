<template>
    <div>
		<template v-if="editable">
			<vs-table :data="getModalCertificationsList" stripe search noDataText="No se han generado registros" pagination :max-items="5">
				<div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-left">
					<vs-chip 
						v-for="certification in selectedCertificationsList" 
						@click="(item) => selectedCertificationsList.splice(selectedCertificationsList.indexOf(item), 1)"
						closable
						:key="certification.id">
						{{ certification.name }}
					</vs-chip>
				</div>
				<template slot="thead">
					<vs-th class="bold" sort-key="name">GRUPO DE PRODUCTO</vs-th>
					<vs-th class="bold" sort-key="name">ENTIDAD CERTIFICADORA</vs-th>
					<vs-th class="bold" sort-key="name">CERTIFICACION</vs-th>
					<vs-th class="bold">ACCIONES</vs-th>
				</template>
				<template slot-scope="{data}">
					<vs-tr :key="index" v-for="(tableRow, index) in data">
						<vs-td :data="data[index].product_group.name">
							{{ data[index].product_group.name }}
						</vs-td>
						<vs-td :data="data[index].certifying_entity.name">
							{{ data[index].certifying_entity.name }}
						</vs-td>
						<vs-td :data="data[index].name">
							{{ data[index].name }}
						</vs-td>
						<vs-td :data="data[index].id">
							<feather-icon v-if="!isCertificationAlreadySelected(data[index])" icon="PlusIcon" svgClasses="w-5 h-5 hover:text-success stroke-current cursor-pointer text-success" @click.stop="handleAddCertification(data[index])" />
							<feather-icon v-else icon="XIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer text-danger" @click.stop="(item) => selectedCertificationsList.splice(selectedCertificationsList.indexOf(data[index]), 1)" />
						</vs-td>
					</vs-tr>
				</template>
			</vs-table>
		</template>
		<template v-else>
			<div class="vx-row">
				<div class="vx-col w-full my-4">
					<vs-chip 
						v-for="certification in selectedCertificationsList" 
						:key="certification.id">
						{{ certification.name }}
					</vs-chip>
				</div>
            </div>
		</template>
	</div>
</template>

<script>
export default {
	name: 'CertificationsTable',
    props: {
        selectedCertificationsList: { type: Array, required: true },
        certificationsList: { type: Array, required: true },
        currentModel: { type: Object, required: true },
		editable: { type: Boolean, required: false, default: true }
    },
	methods: {
		async handleOnSave() {
			try {
				this.showLoading(true);
				let {data} = await axios.post(`/api/v1/certifications/model/${this.currentModel.id}`, { selectedCertificationsList: this.selectedCertificationsList });
				if(!data.success) {
                    this.onError = true;
                    this.errorMsg = data.message;
                    this.showLoading(false);
                    return;
                }

				this.showLoading(false);
				return true;
      		} 
      		catch (error) {
        		console.log(error);
        		this.warn(error);
        		this.onError = true;
      		}
		},
		isCertificationAlreadySelected(newCertification) {
			return this.selectedCertificationsList.find((certification) => newCertification.id == certification.id);
		},
		handleAddCertification(newCertification) {
			if(this.selectedCertificationsList.find((certification) => newCertification.id == certification.id))
				return;
			this.selectedCertificationsList.push(newCertification);
		},
	},
    computed: {
        getModalCertificationsList() {
			return this.certificationsList.filter((certification) => certification.product_group.id == this.currentModel.product_group_id);
        }
    }
}
</script>